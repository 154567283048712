<template>
    <div class="draft-box">
        <nav-index :title="'草稿箱'"/>
        <div class="draft_card" v-if="lists.length > 0">
            <Card :lists="lists" :isDraft="true"/>
        </div>
        <div class="none-list" v-else>
            <img src="../../assets/images/none.svg" alt="">
            <div class="none">没有更多内容</div>
        </div>
    </div>
</template>

<script>
import NavIndex from '../../components/nav/index.vue'
import Card from '../../components/card/index.vue'
import { queryAllDraftByUid } from '@/api/api'

export default {
  name: 'DraftBox',
  components: {
    NavIndex,
    Card
  },
  created(){
    this.getData(this.limit)
  },
  data(){
      return {
        lists:[],
        limit: 10,
        isLogin: localStorage.getItem('token') ? true : false,
      }
  },
  methods:{
    getData(limit){
            // this.delayShow = true
            let uidTemp = this.isLogin ? JSON.parse(localStorage.getItem('userInfo'))[0].uid : ''
            this.uid =  this.$route.query.uid ? this.$route.query.uid : uidTemp
            if(this.limit === 10){
                this.$store.commit('Set_Loading_State', true)
            }
            queryAllDraftByUid({ limit: limit, uid:this.uid }).then((res)=>{
                let tempArr = []
                this.total = res.data.data.count //数据库中返回的所有草稿总数
                res.data.data.data.forEach((item)=>{
                let temp = {}
                temp.did = item.did
                temp.title = item.title
                temp.context = item.context
                // 图片显示前三张
                let imgUrlArr = item.miniImgUrl ? item.miniImgUrl.split(',') : []
                temp.articleImg = {}
                if(imgUrlArr.length === 1 || imgUrlArr.length === 2){
                    temp.articleImg.img_right = imgUrlArr[0]
                    temp.articleImg.total = 1 // 显示1张
                } else if(imgUrlArr.length >= 3){
                    temp.articleImg.img_right = imgUrlArr[2]
                    temp.articleImg.img_center = imgUrlArr[1]
                    temp.articleImg.img_left = imgUrlArr[0]
                    temp.articleImg.total = 3 // 显示3张
                } else {
                    temp.articleImg.total = 0 // 不显示图片
                }
                tempArr.push(temp)
                })
                this.lists = tempArr
                setTimeout(()=>{
                    if(this.limit === 10){
                        this.$store.commit('Set_Loading_State', false)
                    }
                },300)
                // setTimeout(()=>{
                //     this.delayShow = false
                // },300)
            })
        },
  },
  props:{

  }
}
</script>

<style scoped lang="scss">
.draft-box {
    margin-top:50px;
    background: #f3f3f3;
    .draft_card {
        padding-top: 5px;
    }
    .none-list {
        margin: 180px auto;
        width: 100%;
        max-width: 625px;
        position: fixed;
        .none {
            margin: 10px 0 0;
            color: #969699;
        }
    }
}
</style>
